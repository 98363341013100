<template>
  <v-app id="login">
    <v-container class="fill-height" style="opacity:90%">
      <!-- <v-content>
      <v-container class="fill-height fluid">-->
      <!-- <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">-->

      <!-- <v-card
        class="mx-auto d-flex justify-center align-center mb-6"
       
      >-->
      <v-row no-gutters class="justify-center align-center">
        <v-card
          height="90%"
          min-width="300"
          elevation="10"
          class="align-right pa-0"
        >
          <v-card
            elevation="0"
            max-width="450"
            height="100%"
            class="align-center pa-10"
          >
            <div id="logo">
              <picture>
                <source
                  srcset="@/assets/topo_login_mobile.png"
                  media="(max-width: 600px)"
                />
                <img style src="@/assets/topo_login.png" />
              </picture>
            </div>
          </v-card>

          <v-card elevation="" class="align-right pa-5">
            <p style="font-size:24px">Bem-vindo</p>
            <p style="font-size:16px">Entre com suas credenciais</p>
          </v-card>

          <v-card elevation="0" class=" ">
            <v-card-text>
              <v-form>
                <v-text-field
                  class="black-text"
                  label="Email"
                  ref="usuario"
                  name="email"
                  v-model="login.email"
                  prepend-inner-icon="person"
                  type="text"
                  outlined
                  dense
                />

                <v-text-field
                  id="password"
                  label="Senha"
                  name="senha"
                  ref="senha"
                  @keyup.enter="logar()"
                  v-model="login.senha"
                  prepend-inner-icon="lock"
                  type="password"
                  outlined
                  dense
                />
                <div>
                  <v-btn
                    block
                    x-large
                    :loading="loading"
                    class="ma-0 white--text"
                    @click="logar()"
                    color="botao_sucesso"
                    >Acessar o Sistema</v-btn
                  >

                  <v-spacer />
                </div>
                <div class="pt-10 ">
                  <!-- <span class="subtitle-1">Esqueceu a senha </span>-->
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex flex-column mb-5 justify-center">
              <v-img width="127" height="57" src="@/assets/logo.png"></v-img>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-row>
      <!--</v-card>-->
      <!-- </v-col>
        </v-row>-->
      <!--</v-container>-->
      <v-snackbar
        :timeout="tempo"
        :color="error"
        :multi-line="'multi-line'"
        :vertical="mode === 'vertical'"
        :top="true"
        v-model="snackbar"
      >
        {{ textSnack }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  mounted() {
    this.$refs.usuario.focus();
  },
  data: () => {
    return {
      loading: false,
      login: {
        email: "",
        senha: "",
      },
      textSnack: "",
      snackbar: false,
      snackbarSuccess: false,
      tempo: 3000,
      mode: "",
      info: "f1f1f1",
      error: "error",
    };
  },
  methods: {
    async logar() {
      if (this.loading == true) {
        return false;
      }
      this.loading = true;

      //var self = axios;
      await axios
        .post(`users/login`, this.login)
        .then((response) => {
          if (response.data.error) {
            this.textSnack = response.data.error;
            this.snackbar = true;
            this.loading = false;
          } else {
            window.sessionStorage.setItem("token", response.data.token);
            window.sessionStorage.setItem("nome", response.data.nome);
            // EventBus.$emit("mudounome");
            window.sessionStorage.setItem("cnpj", response.data.cnpj);
            //window.sessionStorage.setItem("cnpj", 586);
            setTimeout(() => {
              //alert("abrir");
              /*self.default.headers.common["Authorization"] =
                "bearer " + window.sessionStorage.getItem("token");*/

              this.loading = false;
              this.$router.push("dashboard/principal");
            }, 500);

            //return true;
          }

          //  console.log(response.data.token)
        })
        .catch((e) => {
          this.loading = false;
          if (!Array.isArray(e.response.data.error)) {
            let itens = [];
            for (var prop in e.response.data.error) {
              itens.push(e.response.data.error[prop][0]);
            }

            this.textSnack = itens.join("");
            this.snackbar = true;
          } else {
            this.textSnack = e.response.data.error;
            this.snackbar = true;
          }
          this.$refs.senha.focus();
        });
    },
  },
};
</script>
<style scoped>
#login {
  background-image: url("~@/assets/fundo.png");
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.v-sheet.v-card {
  border-radius: 10px;
}
</style>
